import React from "react";
import { createStage } from "../gameHelpers";

//Types
import type { Player } from "./UsePlayer";

export type StageCell = [string | number, string];
export type Stage = StageCell[][];

export const useStage = (player: Player, resetPlayer: () => void) => {
  const [stage, setStage] = React.useState(createStage());
  const [rowsCleared, setRowsCleared] = React.useState(0);

  React.useEffect(() => {
    if (!player.pos) return;

    setRowsCleared(0);

    const sweepRows = (newStage: Stage): Stage => {
      return newStage.reduce((ack, row) => {
        // If we do not find a 0 it means that the row if full and should be cleared
        if (row.findIndex((cell) => cell[0] === 0) === -1) {
          setRowsCleared((prev) => prev + 1);
          // Create an empty fow at the beginning of the array to push the tetrominos down
          // Instead of returning the cleared row
          ack.unshift(
            new Array(newStage[0].length).fill([0, "clear"] as StageCell)
          );
          return ack;
        }
        ack.push(row);
        return ack;
      }, [] as Stage);
    };

    const updateStage = (prevStage: Stage): Stage => {
      // First clear the stage
      // If it says "clear" but don't have a 0 it means that it is players move and should be cleard
      const newStage = prevStage.map(
        (row) =>
          row.map((cell) =>
            cell[1] === "clear" ? [0, "clear"] : cell
          ) as StageCell[]
      );

      // Then draw the tetromino
      player.tetromino.forEach((row, y) => {
        row.forEach((value, x) => {
          if (value !== 0) {
            newStage[y + player.pos.y][x + player.pos.x] = [
              value,
              `${player.collided ? "merged" : "clear"}`,
            ];
          }
        });
      });

      if (player.collided) {
        resetPlayer();
        return sweepRows(newStage);
      }

      return newStage;
    };

    setStage((prev) => updateStage(prev));
  }, [player.pos, player.tetromino, player.collided, resetPlayer]);

  return { stage, setStage, rowsCleared };
};
